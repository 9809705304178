<template>
  <div>
    <Navbar />
    <div
      class="h-screen bg-cover flex items-center justify-center"
      :style="{ backgroundImage: 'url(/img/home/header-bg.png)' }">
      <div class="relative h-full">
        <div class="standard-spacing w-full h-full grid grid-cols-5 space-x-16">
          <div class="col-span-5 lg:col-span-3 flex flex-col justify-center">
            <div class="heading-basic font-light tracking-tight">Wellbeing & Performance,</div>
            <div class="heading-basic font-decorative mt-4 inline-flex">Elevated</div>
            <div class="mt-4 subheading lg:w-3/4">
              At Hoap, we pair smart technology with education, to arm your talent and leaders with the skills they need
              to thrive. Embedding seamlessly into your organisation's current ecosystem, we partner with you to drive
              performance through a culture of wellbeing.
            </div>
          </div>
          <div class="hidden lg:flex col-span-2 items-center">
            <div class="border-2 border-white p-6 rounded-t-full h-96 w-full lg:h-128 relative">
              <div class="border border-white p-2 rounded-t-full h-96 lg:h-128 w-full absolute -bottom-10 -right-10">
                <nuxt-img
                  format="webp"
                  preload
                  :src="headerImageUrl"
                  class="bg-cloudy-blue-700 border border-white object-cover rounded-t-full w-full h-full" />
              </div>
            </div>
          </div>
        </div>
        <HoapIcon
          icon="ArrowDown"
          class="h-12 md:h-24 lg:h-36 animate-bounce mt-16 mx-auto absolute left-0 right-0 bottom-0" />
      </div>
    </div>
    <div class="bg-gradient-midnight">
      <div class="standard-spacing standard-spacing-y">
        <div class="md:flex md:space-x-8 items-center">
          <div class="w-full md:w-2/5">
            <ArchImage
              src="https://dcctsvhz4yusz.cloudfront.net/d902efc2-e0c7-4ea6-a8f3-272025a21329.jpg"
              class="mx-auto" />
          </div>
          <div class="w-full md:w-3/5 mt-4 md:mt-0 text-center md:text-left">
            <p class="subheading">
              Our approach to wellbeing has been tried and tested by high performance organisations around the world.
            </p>
            <p class="subheading mt-8">
              We elevate teams with evidence-based knowledge and a focus on technology and education.
            </p>
          </div>
        </div>
        <div class="standard-spacing-t">
          <div class="grid grid-cols-1 md:grid-cols-3 gap-x-8 md:gap-x-32 text-center">
            <HowHoapWorksColumn
              title="Technology"
              :paragraphs="[
                `We are the industry leaders in monitoring personal and team wellbeing.`,
                `Our smart technology enables your organisation to know how talent are really feeling, giving your leaders the opportunity to make meaningful change at the time it is needed.`,
              ]" />
            <HowHoapWorksColumn
              title="Education"
              :paragraphs="[
                `We ensure your talent and leaders are equipped with the knowledge and skills they need to drive personal and team wellbeing.`,
                `Our tools and resources, original expert content and multiple training options mean your organisation always has access to an appropriate level of education.`,
              ]"
              class="mt-8 md:mt-0" />
            <HowHoapWorksColumn
              title="Partnership"
              :paragraphs="[
                `We partner with your organisation to embed wellbeing into your culture.`,
                `We form part of your organisation's ecosystem and work with you to not only implement new initiatives, but optimise offerings you already have in place.`,
              ]"
              class="mt-8 md:mt-0" />
          </div>
        </div>
        <div class="text-center lg:px-32 standard-spacing-t">
          <p class="subheading">
            Welcome to the workplace wellbeing <span class="font-decorative">evolution</span>. Take a look at how our
            smart technology can help your team to thrive.
          </p>
          <FeaturedVideo
            vimeo-video-id="747122696"
            thumbnailUrl="https://dcctsvhz4yusz.cloudfront.net/78b99f04-26c8-43ab-9653-a95e0750d42d.png"
            label="How Hoap's shaping the future of workplace wellbeing"
            class="pt-8 lg:pt-16" />
        </div>
      </div>
    </div>

    <Subscribe />
    <div class="bg-green-700 standard-spacing-t">
      <div class="standard-spacing">
        <ClientOnly>
          <div v-if="featuredOriginals && featuredOriginals.length" class="w-full mb-24">
            <div class="text-center lg:w-full mx-auto">
              <h2 class="heading-1">Learn from industry leaders</h2>
              <p class="mt-8 subheading">
                A sneak peak of the latest original in app content from Hoap. To watch the complete series, join Hoap
                today.
              </p>
            </div>
            <div class="mt-16 grid grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
              <LibraryCard
                v-for="original in featuredOriginals"
                :key="original.title"
                v-bind="original"
                class="col-span-1" />
            </div>
          </div>
        </ClientOnly>

        <div class="flex flex-col lg:flex-row items-center">
          <div class="heading-1 text-center lg:text-left mb-8 lg:mb-0 lg:mr-8 lg:w-1/2">
            Why do organisations love using Hoap?
          </div>
          <HoapVideo
            vimeoVideoId="759378282"
            thumbnailUrl="https://dcctsvhz4yusz.cloudfront.net/c5a5d386-655c-45b6-8f9b-8fb4a365647d.jpg"
            class="w-full lg:w-3/4" />
        </div>
        <h2 class="text-center heading-2 standard-spacing-t">As seen in</h2>
        <div class="flex items-center flex-wrap justify-center mt-4 space-x-4 md:space-x-8">
          <img v-lazy="'/img/home/australian.png'" alt="The Australian" class="w-24 md:w-48" />
          <img v-lazy="'/img/home/indaily.png'" alt="InDaily" class="w-8 md:w-16" />
          <img v-lazy="'/img/home/financial-review.png'" alt="The Financial Review" class="w-24 md:w-48" />
          <img v-lazy="'/img/home/acuity.png'" alt="Acuity" class="w-16 md:w-24" />
          <img v-lazy="'/img/home/advertiser.png'" alt="The Advertiser" class="w-24 md:w-44" />
          <img v-lazy="'/img/home/business-news.png'" alt="Business News Australia" class="w-24 md:w-48" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useQuery } from '@vue/apollo-composable'
  import { GET_FEATURED_ORIGINALS } from '../queries'

  const { result } = useQuery(GET_FEATURED_ORIGINALS)
  const featuredOriginals = computed(() =>
    result.value?.originals.map((original: { image: { url: string } }) => ({
      ...original,
      imageUrl: original.image?.url,
      type: 'video',
    }))
  )

  const headerImageUrl = 'https://dcctsvhz4yusz.cloudfront.net/d79c19d0-ebdc-405b-b1ec-6754db03a64b.jpg'

  setMetadata({
    pageTitle: 'Home',
    imageUrl: 'https://dcctsvhz4yusz.cloudfront.net/64d4ddad-923a-4519-aeb5-2de617cee8ac.png',
  })
</script>
