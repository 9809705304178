<template>
  <div>
    <div
      tabindex="0"
      @click="showVideo({ id: vimeoVideoId })"
      @keydown.enter="showVideo({ id: vimeoVideoId })"
      class="group overflow-hidden rounded-4xl bg-cloudy-blue-700 aspect-16/9 flex items-end relative w-full cursor-pointer">
      <img
        v-if="thumbnailUrl"
        v-lazy="thumbnailUrl"
        class="w-full h-full transition-quick object-cover group-focus:scale-110 group-hover:scale-105" />
      <div class="absolute inset-0 pointer-events-none bg-scrim-full" />
      <div
        v-if="label"
        class="text-black bg-white text-sm lg:text-2xl font-medium text-center w-full rounded-b-4xl p-2 lg:p-4 absolute bottom-0">
        {{ label }}
      </div>
      <div class="absolute inset-0 flex items-center justify-center rounded-4xl">
        <div class="p-1 border-3 rounded-full border-white">
          <HoapIcon icon="Play" class="w-8 lg:w-12 h-8 lg:h-12 text-white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { HoapIcon } from '..'
  import { useLightbox } from '../../composables/useLightbox'

  const { showVideo } = useLightbox()

  defineProps({
    label: String,
    vimeoVideoId: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
  })
</script>
