<template>
  <div>
    <div
      tabindex="0"
      @click="showVideo({ id: vimeoVideoId })"
      @keydown.enter="showVideo({ id: vimeoVideoId })"
      class="group overflow-hidden bg-cloudy-blue-700 aspect-16/9 flex items-end relative w-full cursor-pointer">
      <img
        v-lazy="thumbnailUrl"
        class="w-full h-full transition-quick object-cover group-focus:scale-110 group-hover:scale-105" />
      <div class="absolute inset-0 flex items-center justify-center bg-scrim-full">
        <div class="p-1 border-3 rounded-full border-white">
          <HoapIcon icon="Play" class="w-12 h-12 text-white" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { HoapIcon } from '..'
  import { useLightbox } from '../../composables/useLightbox'

  const { showVideo } = useLightbox()

  defineProps({
    vimeoVideoId: {
      type: String,
      required: true,
    },
    thumbnailUrl: {
      type: String,
      required: true,
    },
  })
</script>
